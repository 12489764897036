import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <p>{`Excel est le couteau suisse de la vie de bureau. Le néophyte et l'expert l'utilisent quand rien d'autre de plus approprié n'est disponible, pour eux mêmes et quelques heures ou ... pour cent personnes et quelques années.`}</p>
    <br />
    <p>{`Conteneur polyvalent d'information structurée:`}</p>
    <ul>
      <li parentName="ul">{`base de donnée `}</li>
      <li parentName="ul">{`base de connaissance`}</li>
      <li parentName="ul">{`formulaires`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <br />
    <p>{`Ébauche opérationnelle d'outil métier:`}</p>
    <ul>
      <li parentName="ul">{`calculatrice spécialisée (devis, stocks, commissions, ...)`}</li>
      <li parentName="ul">{`générateur de rapport et de graphiques `}</li>
      <li parentName="ul">{`reporting`}</li>
      <li parentName="ul">{`todo list et suivi de projet`}</li>
      <li parentName="ul">{`planification (congés, occupation de salle, ...)`}</li>
      <li parentName="ul">{`budget et comptabilité`}</li>
      <li parentName="ul">{`...`}</li>
    </ul>
    <br />
    <p>{`C'est certainement l'outil de génie logiciel le plus utilisé au monde... Et le pire cauchemar du management et de la Direction Informatique qui craignent que de précieuses connaissances et données ignorées restent enfouies dans la prolifération anarchique de fichiers non sérieusement gérés.`}</p>
    <br />
    <p>{`Pourtant convenablement utilisé, seul ou en association avec d'autres technologies, Excel supporte de façon tout à fait satisfaisante, la visualisation d'indicateurs et de tableaux de bord:`}</p>
    <ul>
      <li parentName="ul">{`extraction de données (import fichier, requêtes, ...)`}</li>
      <li parentName="ul">{`calculs, filtres, consolidation, ...`}</li>
      <li parentName="ul">{`présentation graphique et tabulaire`}</li>
      <li parentName="ul">{`mise en page, impression`}</li>
    </ul>
    <br />
    <p>{`C'est dans beaucoup de cas une solution simple, robuste et peu couteuse qu'il faut considérer. On est souvent agréablement surpris de ce qu'il est possible d'obtenir d'une feuille Excel bien utilisée. Bien sur il y a la tentation des macros et de VBA qui permettent de transformer Excel en une véritable application aux fonctionnalités diverses. La plupart de ceux qui ont essayer découvre le casse tête de la maintenance. A utiliser seulement avec prudence et si possible accompagné de professionnels compétents. `}</p>
    <br />
    <p>{`Parmi les gros intérêts de l'approche: sa flexibilité. L'utilisateur garde le contrôle de l'apparence finale: mise en page, couleurs, tailles, changement de nom de libellés, ... `}</p>
    <br />
    <p>{`Pour les organisations déjà équipées en outils généralistes d'aide à la décision, le tableur Excel ne sera qu'un produit intermédiaire, une maquette cahier des charges de développements ou paramétrages dédiés.`}</p>
    <br />
    <p>{`Tout ce qui est vrai pour Excel l'est aussi pour son concurrent direct GoogleSheet. La différence est que l'on rencontre que peu d'entreprises françaises qui osent confier leur données confidentielles à Google. Il est bien plus évident que les données sont dans le `}<strong parentName="p">{`Cloud`}</strong>{` quand on utilise GoogleSheet. C'est plus insidieux avec Excel.`}</p>
    <br />
    <p>{`Il faut noter l'apparition d'une nouvelle classe d'outils à tout faire qui englobe le texte et les tables. Au premier rang se trouve `}<a parentName="p" {...{
        "href": "https://medium.com/@alain.roan/notion-so-un-cerveau-num%C3%A9rique-%C3%A0-plusieurs-87a09a7e6aa5"
      }}>{`Notion`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      